import { useEffect, useRef, useState } from 'react';
import './Home.css';
import Camera from '../components/Camera';

function Home() {
    // const cameraVideoStream = document.getElementById('camera-stream')
    // const shutterButton = document.getElementById('shutter')
    // const canvas = document.getElementById('canvas')

    // const getVideo = () => {
    //     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia({ video: true })) {
    //         navigator.mediaDevices
    //             .getUserMedia({ video: true })
    //             .then((stream) => {
    //                 cameraVideoStream.srcObject = stream
    //                 cameraVideoStream.play()
    //             })
    //     }
    // } 


    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const [hasPhoto, setHasPhoto] = useState(false)
    

    const getVideo = () => {
        navigator.mediaDevices.getUserMedia({
            video:{
                width:1920,
                height:1080
            }
        }).then(stream => {
            let video = videoRef.current;
            video.srcObject = stream
            video.play();
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getVideo();
    },[videoRef])
    return (
        <div id="App">
            <div className="camera">
                <video ref={videoRef}></video>
                <button>SNAP</button>
            </div>
            <div className={'hasPhoto' + (hasPhoto? 'hasPhoto':'')}>
                <canvas ref={photoRef}></canvas>
                <button>Close</button>
            </div>
        </div>
    );
}

export default Home;
